import React from "react"
import Layout from "../../components/layout"

import newsPostStyles from "../../styles/templates/news-post.module.css"

import award from "../../images/news/article-1/special-tanglaw-award.jpg"
import awarding from "../../images/news/article-1/awarding.jpg"

const Article1Page = () => {
    return (
        <Layout location="news">
        <div className={ newsPostStyles.article }>
            <h1>SPECIAL TANGLAW AWARD</h1>
            <h3>November 26, 2020</h3>
            <div className={ newsPostStyles.imageRow }>
                <img className={newsPostStyles.poster} src={award} alt=""/>
                <img src={awarding} alt=""/>
            </div>
            <p>The special Gawad Tanglaw award by the Ad Foundation of the Philippines being presented to the legendary advertising honcho JJ Calero during the 10th Araw Values Awards. Team ED, with the assistance of sister unit Creative Caboose film & digital prod & post-prod, filmed and produced the video presentation of the special segment, and assisted in the general execution of the event. </p>
            <button onClick={() => {
                window.history.back()
            }}>Go Back</button>
        </div>
        </Layout>
    )
}

export default Article1Page;